<template>
  <h2 class="text-30 leading-38 font-bold text-kobalt-blue font-kobalt break-words">
      <slot />
  </h2>
</template>

<script>
export default {

}
</script>

<style>

</style>
