<template>
  <h1 class="text-50 leading-54 lg:text-72 font-bold lg:leading-72 font-kobalt break-words">
      <slot />
  </h1>
</template>

<script>
export default {

}
</script>

<style>

</style>
