
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import VueI18n from 'vue-i18n';
import { InertiaApp } from '@inertiajs/inertia-vue'
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import '@/jsencrypt.js';
import moment from 'moment';
import VModal from 'vue-js-modal'
import 'vue-select/dist/vue-select.css';
import VueConfetti from 'vue-confetti';
import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";

// Global Components
import LabelText from '@/components/text/LabelText.vue';
import BodyCopy from '@/components/text/BodyCopy.vue';
import BodyCopyBig from '@/components/text/BodyCopyBig.vue';
import SubHeading from '@/components/text/SubHeading.vue';
import Heading from '@/components/text/Heading.vue';

const isLive = (url) => {
    const parts = url.split('.');
    const subdomain = parts.shift();

    if (parts.indexOf('kobalt') > -1) {
        return false;
    }

  return ['passtransfer.com', `${subdomain}.passtransfer.com`].includes(url);
}

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (!isLive(window.location.hostname)) {
    flare.light();
}

Vue.component('LabelText', LabelText);
Vue.component('BodyCopy', BodyCopy);
Vue.component('BodyCopyBig', BodyCopyBig);
Vue.component('SubHeading', SubHeading);
Vue.component('Heading', Heading);

// install

Vue.use(VueConfetti)
Vue.use(VueI18n)
Vue.use(VueClipboard)
Vue.use(InertiaApp)
Vue.use(VModal)
Vue.use(flareVue);

const app = document.getElementById('app')

Vue.mixin({
    methods: {
        route: function (name, options) {

            if (!name) {
                return route();
            }

            if (!this.$page.tenant) {
                return route(name, options);
            }

            return route(`tenant.${name}`, { tenant: this.$page.tenant.subdomain, ...options })
        }
    }
});



import * as translationsEnglish from './locales/en.json'
import * as translationsDutch from './locales/nl.json'

const messages = {
    'en': translationsEnglish.default,
    'nl': translationsDutch.default
}

const userLang = navigator.language || navigator.userLanguage;

const nl = 'nl';

let locale = 'en';

if(userLang.indexOf(nl) != -1) {
    locale = 'nl'
}

if (sessionStorage.getItem('passtransfer_lang')) {
  locale = sessionStorage.getItem('passtransfer_lang');
}

const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: 'nl',
    messages
});

new Vue({
    i18n,
    render: h => h(InertiaApp, {
        props: {
            initialPage: JSON.parse(app.dataset.page),
            resolveComponent: name => import(`./pages/${name}`).then(module => module && module.default),
        },
    }),
}).$mount(app)
