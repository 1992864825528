<template>
  <h2 class=" text-20 leading-28 lg:text-28 lg:leading-38 font-normal text-kobalt-blue font-inter">
      <slot />
  </h2>
</template>

<script>
export default {

}
</script>

<style>

</style>
