<template>
  <p class="text-13 leading-16 font-medium text-kobalt-blue font-kobalt">
      <slot />
  </p>
</template>

<script>
export default {

}
</script>

<style>

</style>
