<template>
  <p class="font-inter text-15 leading-25 font-regular text-kobalt-blue">
      <slot />
  </p>
</template>

<script>
export default {

}
</script>

<style>

</style>
